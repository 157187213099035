var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"shop-item-virtual-placement"},[_c('div',{staticClass:"shop-item-virtual-placement__background",style:({
      backgroundImage: ("url(" + (_vm.artwork.header_image) + ")"),
    })}),_c('div',{staticClass:"shop-item-virtual-placement__content"},[_c('h2',{staticClass:"shop-item-virtual-placement__title text-title"},[_vm._v("Virtual Placement")]),_vm._m(0),_c('ValidationObserver',{ref:"observer",staticClass:"shop-item-virtual-placement__form",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var invalid = ref.invalid;
return [_c('ValidationProvider',{staticClass:"shop-item-virtual-placement__input",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('SubscriptionInput',{staticClass:"form-control--inquire",attrs:{"placeholder":"Art Name","disabled":true,"validateError":errors[0]},model:{value:(_vm.form.artName),callback:function ($$v) {_vm.$set(_vm.form, "artName", $$v)},expression:"form.artName"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"shop-item-virtual-placement__input",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('SubscriptionInput',{staticClass:"form-control--inquire",attrs:{"placeholder":"Your Name","validateError":errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"shop-item-virtual-placement__input",attrs:{"tag":"div","rules":{ required: true, email: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('SubscriptionInput',{staticClass:"form-control--inquire",attrs:{"placeholder":"Email Address","validateError":errors[0]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"shop-item-virtual-placement__input",attrs:{"tag":"div","rules":{ required: true, phone_number: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('SubscriptionInput',{staticClass:"form-control--inquire",attrs:{"placeholder":"Phone Number","validateError":errors[0]},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"shop-item-virtual-placement__input",attrs:{"tag":"div","rules":{ min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('SubscriptionInput',{staticClass:"form-control--inquire",attrs:{"placeholder":"Location","validateError":errors[0]},model:{value:(_vm.form.service_location),callback:function ($$v) {_vm.$set(_vm.form, "service_location", $$v)},expression:"form.service_location"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"shop-item-virtual-placement__input",attrs:{"tag":"div"}},[_c('DragAndDropFiles',{staticClass:"drag-and-drop--lg",attrs:{"placeholder":"Upload image of proposed space","size":"2","file":_vm.form.files},on:{"input":function($event){_vm.form.files = $event.files}}})],1),_c('ValidationProvider',{staticClass:"shop-item-virtual-placement__input",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('BGATextarea',{staticClass:"form-control--inquire",attrs:{"placeholder":"Describe features in the image (size of door, window frame, wall height and width)","maxLength":255,"validateError":errors[0]},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}],null,true)}),(_vm.captchaVerifided)?_c('BaseButton',{staticClass:"shop-item-virtual-placement__button button--white",attrs:{"text":"SUBMIT","disabled":invalid || _vm.submiting},on:{"click":_vm.submit}}):_c('VueRecaptcha',{ref:"recaptcha",attrs:{"sitekey":_vm.sitekey},on:{"verify":function($event){_vm.captchaVerifided = true},"error":function($event){_vm.captchaVerifided = false}}})]}}])})],1),_c('IconButton',{staticClass:"shop-item-virtual-placement__close button--md button--outline-white",on:{"click":function($event){return _vm.$router.push(("/artworks/" + _vm.artworkSlug))}}},[_c('IconClose',{attrs:{"width":"18","height":"18"}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"shop-item-virtual-placement__description text-description"},[_vm._v(" Fill out the form below or contact Tom Grotta at "),_c('a',{staticClass:"shop-item-virtual-placement__description-link text-description",attrs:{"href":"mailto:art@browngrotta.com"}},[_vm._v(" art@browngrotta.com")]),_vm._v(". ")])}]

export { render, staticRenderFns }